import { useCallback, useState, useEffect } from "react";
import API from "../../apis";
import {
  TenantModel,
  Client,
  InsuranceRequest,
  ProjectMissing,
} from "../../models";
import * as ObjectTypes from "../../constants/objectTypes";
import * as TenantModes from "../../constants/tenantModes";

import { showToastMessage, validateEmail } from "../../utils";
import { toast } from "react-toastify";

export function useLoad() {
  const [client, setClient] = useState<Client>({
    clientId: 0,
    clientName: "",
    clientCode: "",
    clientDomain: "",
    loggedInUserId: null,
  });
  const [authenticating, setAuthenticating] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [tenant, setTenant] = useState<TenantModel>({
    id: 0,
    name: "",
    code: "",
    email: "",
    projectId: 0,
    projectName: "",
    projectCode: "",
    requirementList: [],
    tenantMode: 0,
    kriyagoProjectId: 0,
    requirements: [],
  });

  const [insurance, setInsurance] = useState<InsuranceRequest>({
    objectId: 0,
    objectType: ObjectTypes.TENANT,
    projectId: 0,
    email: "",
    requirementId: null,
    clientId: 0,
    vendor: null,
    tenant: null,
    userId: null,
    tabId: 0,
  });

  const [tenantName, setTenantName] = useState<string | null>(null);
  const [emailAddres, setEmailAddress] = useState<string | null>(null);
  const [projectMissing, setProjectMissing] = useState<ProjectMissing | null>(
    null
  );
  const [loadingProject, setLoadingProject] = useState(true);

  const onLoad = useCallback(async () => {
    try {
      let clientData: Client;
      let insuranceData: InsuranceRequest = {
        objectId: 0,
        objectType: ObjectTypes.TENANT,
        projectId: 0,
        email: "",
        requirementId: null,
        clientId: 0,
        vendor: null,
        tenant: null,
        userId: null,
        tabId: 0,
      };

      let account: any;

      try {
        setAuthenticating(true);
        let parentUrl = window.parent.document.referrer;
        account = new URLSearchParams(window.location.search).get("account");
        let token = new URLSearchParams(window.location.search).get("token");
        let code = new URLSearchParams(window.location.search).get("code");
        if (!account) {
          showToastMessage("Company not selected!");
          setAuthenticating(false);
          setAuthenticated(false);

          return;
        }
        const response = await API.get(
          `VerifyClient?account=${account}&domainUrl=${parentUrl}&token=${token}&code=${code}`
        );
        if (response && response.data) {
          API.defaults.headers["Token"] = token;
          API.defaults.headers["ClientCode"] = response.data.clientCode;
          API.defaults.headers["Code"] = code;
          clientData = response.data;
          setClient(response.data);
          setAuthenticated(true);
          setAuthenticating(false);
        } else {
          setAuthenticated(false);
          setAuthenticating(false);
          return;
        }
      } catch (e) {
        setAuthenticating(false);
        setAuthenticated(false);
        return;
      }
      try {
        let code = new URLSearchParams(window.location.search).get("code");

        let name = new URLSearchParams(window.location.search).get("name");
        if (name) {
          setTenantName(name);
        }
        if (!code) {
          showToastMessage("Tenant Name Or Tenant Code not selected ");
          return;
        }
        let email = new URLSearchParams(window.location.search).get("email");
        setEmailAddress(email);

        const tenantResponse = await API.get(
          `GetTenant?code=${code}&clientId=${clientData.clientId}`
        );

        setTenant(tenantResponse.data);

        insuranceData = {
          objectId: tenantResponse?.data?.id,
          objectType: ObjectTypes.TENANT,
          projectId: tenantResponse?.data?.projectId,
          email: email ?? "",
          requirementId: tenantResponse?.data?.tenantMode === TenantModes.LEASEREQUIREMENT ? 0 : null,
          clientId: clientData.clientId,
          vendor: null,
          userId: clientData.loggedInUserId,
          tabId: 0,
          tenant: tenantResponse?.data
            ? null
            : {
                id: 0,
                name: name ?? "",
                code: code ?? "",
                email: email ?? "",
                projectId: 0,
                projectCode: "",
                projectName: "",
                requirementList: [],
                tenantMode: tenantResponse?.data?.tenantMode ?? 0,
                kriyagoProjectId: tenantResponse?.data?.kriyagoProjectId,
                requirements: [],
              },
        };

        setInsurance(insuranceData);

        if (!tenantResponse.data) {
          let project = new URLSearchParams(window.location.search).get(
            "project"
          );

          let projectName = new URLSearchParams(window.location.search).get(
            "projectName"
          );

          if (!project) {
            showToastMessage("Project is not selected");
          } else {
            try {
              setLoadingProject(true);
              const projectResponse = await API.get(
                `GetTenantProject?project=${project}&clientId=${clientData.clientId}`
              );

              setLoadingProject(false);

              setInsurance({
                ...insuranceData,
                requirementId: projectResponse?.data?.tenantMode === TenantModes.LEASEREQUIREMENT ? 0 : null,
                projectId: projectResponse.data.id,
                tenant: {
                  id: 0,
                  name: name ?? "",
                  code: code ?? "",
                  email: email ?? "",
                  projectId: projectResponse.data.id,
                  projectCode: projectResponse.data.code,
                  projectName: projectResponse.data.name,
                  requirementList: projectResponse.data.requirementList,
                  tenantMode: projectResponse.data?.tenantMode ?? 0 ,
                  kriyagoProjectId: projectResponse?.data?.kriyagoProjectId,
                  requirements: projectResponse?.data?.requirements,
                },
              });
              setTenant({
                id: 0,
                name: name ?? "",
                code: code ?? "",
                email: email ?? "",
                projectId: projectResponse.data.id,
                projectCode: projectResponse.data.code,
                projectName: projectResponse.data.name,
                requirementList: projectResponse.data.requirementList,
                tenantMode: projectResponse.data.tenantMode ?? 0 ,
                kriyagoProjectId: projectResponse?.data?.kriyagoProjectId,
                requirements: projectResponse.data.requirements,
              });
            } catch (e) {
              setProjectMissing({
                title: `No property for ${projectName} configured in Jones`,
                message:
                  `Once the property is created, you can invite ${name} to review your insurance requirements and upload their COIs.` +
                  "\n" +
                  "\n" +
                  `You may email the Jones’ Customer Success team to request a new property be configured at`,
                email: "integration@getjones.com",
              });
              setLoadingProject(false);
              //showToastMessage(e.message);
            }
          }
        } else {
          setLoadingProject(false);
        }
      } catch (error :any) {
        showToastMessage(error);
      }
    } catch (e :any) {
      showToastMessage(e.message);
    }
  }, []);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    client,
    authenticating,
    authenticated,
    tenant,
    insurance,
    setInsurance,
    tenantName,
    emailAddres,
    setTenant,
    projectMissing,
    loadingProject,
  };
}

export function useStore() {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(false);

  const onValidate = useCallback(
    async (values: InsuranceRequest, setIsModelOpen, tenant: TenantModel) => {
      try {
        setValidating(true);
        if (
          values.objectId &&
          values.projectId &&
          ((tenant.tenantMode === TenantModes.LEASEREQUIREMENT &&
            !values.requirementId) ||
            values.requirementId !== null) &&
          values.email &&
          validateEmail(values.email)
        ) {
          const response = await API.post("ValidateInsurance", values);
          setValidating(false);
          if (response.data) {
            showToastMessage(
              "Requirement already generated with selected project and requirement"
            );
          } else {
            toast.dismiss();
            setIsModelOpen(true);
          }
        } else if (!values.objectId && validateEmail(values.email)) {
          toast.dismiss();
          setIsModelOpen(true);
        }
        setValidating(false);
      } catch (e :any) {
        setValidating(false);
        showToastMessage(e?.response?.data);
        return true;
      }
    },
    [setValidating]
  );

  const onStore = useCallback(
    async (values) => {
      try {
        setSubmitting(true);
        const response = await API.post("RequestInsurance", values);
        setSubmitting(false);
        if (!response.data) {
          showToastMessage("Submission failed! Please try again.");
        } else {
          window.history.back();
        }
      } catch (e : any) {
        setSubmitting(false);
        showToastMessage(e?.response?.data);
      }
    },
    [setSubmitting]
  );
  return { onStore, submitting, validating, onValidate };
}
