import React, { useCallback, useState } from "react";

import { Card, Form, Col, Row } from "react-bootstrap";
import Select from "react-select";

import { useLoad, useStore } from "./hooks";
import * as ERROR_MESSAGES from "../../constants/errorMessages";
import Popup from "../../components/Popup";

function Vendor() {
  const {
    insurance,
    setInsurance,
    projects,
    vendor,
    authenticating,
    authenticated,
    project,
    setProject,
    vendorName,
  } = useLoad();

  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);

  const { submitting, onStore, onValidate, validating } = useStore();

  const handleProjectChange = useCallback(
    (selectedProject: any) => {
      if (selectedProject) {
        if (selectedProject) {
          setProject(selectedProject);
          setInsurance({
            ...insurance,
            projectId: selectedProject ? selectedProject.id : 0,
            requirementId: null,
          });
        }
      }
    },
    [insurance, setInsurance, setProject]
  );

  const handleRequirementChange = useCallback(
    (selectedRequirement: any) => {
      if (selectedRequirement) {
        setInsurance({
          ...insurance,
          requirementId: selectedRequirement.id,
          tabId: selectedRequirement.tabId,
        });
      }
    },
    [insurance, setInsurance]
  );

  const handleEmailChange = useCallback(
    (e: any) => {
      setInsurance({
        ...insurance,
        email: e.target.value,
      });
    },
    [insurance, setInsurance]
  );

  const handleSendClick = useCallback(() => {
    onValidate(insurance, setIsModelOpen);
  }, [onValidate, setIsModelOpen, insurance]);

  const handleClick = useCallback(() => {
    onStore(insurance);
  }, [insurance, onStore]);

  const onProjectFilter = useCallback((option: any, searchText: any) => {
    if (
      option.data.code.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const onRequirementFilter = useCallback((option: any, searchText: any) => {
    if (
      option.data.description.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const onCancel = useCallback(async () => {
    setIsModelOpen(false);
  }, [ setIsModelOpen]);

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      minHeight: 1,
      paddingTop: 0,
      paddingRight: 2,
      paddingBottom: 1,
      backgroundColor: "#b2dbf1",
      color: state.data.requestSent ? "#8f8686" : "#000",
      "&:hover": {
        backgroundColor: "#d8d8d8",
      },
      paddingLeft: state.data.label ? 20 : 5,
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "#b2dbf1",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "black",
    }),
  };

  if (authenticating) {
    return (
      <div className="p-5">
        <div className="row row no-gutters">
          <div className="col-md-12">
            <div className="alert alert-light text-center">
              <p>Authenticating...</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!authenticated) {
    return (
      <div className="p-5">
        <div className="row no-gutters">
          <div className="col-md-12">
            <div className="alert alert-danger text-center">
              <h1>{ERROR_MESSAGES.AUTH_FAILED_TITLE}</h1>
              <p>{ERROR_MESSAGES.AUTH_FAILED_MESSAGE}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="row no-gutters">
        <div className="col-sm-12">
          <header className="header py-2">
            <div className="container-fluid">
              <h1 className="m-0">Jones Widget - Request COI</h1>
            </div>
          </header>
          <Card style={{ border: 0 }}>
            <Card.Body className="p-0">
              <div className="container px-4 py-4">
                <h1>
                  COI Request for {vendor?.name ? vendor.name : vendor?.code ? vendor.code : vendorName}
                </h1>
                <p>Your request will take effect within the next 24 hours. </p>
                <Form.Group
                  as={Row}
                  controlId="companyName"
                  className="pl-2 pr-2 no-gutters"
                  style={{
                    marginTop: 40,
                  }}
                >
                  <Form.Label column sm="2">
                    Select Jones Property
                  </Form.Label>
                  <Col sm="3">
                    <Select
                      className="custom-select-input"
                      classNamePrefix="name-select"
                      onChange={handleProjectChange}
                      getOptionLabel={(option) =>
                        `${option.name}`
                      }
                      getOptionValue={(option) => `${option}`}
                      isOptionSelected={(option) => {
                        return insurance.projectId === option.id ? true : false;
                      }}
                      value={
                        insurance?.projectId
                          ? projects.find((x) => x.id === insurance.projectId)
                          : null
                      }
                      options={projects}
                      isSearchable={true}
                      filterOption={onProjectFilter}
                      placeholder={"Select"}
                      noOptionsMessage={() => null}
                      styles={customStyles}
                      maxMenuHeight={100}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  controlId="requirement"
                  className="pl-2 pr-2 no-gutters"
                >
                  <Form.Label column sm="2">
                    Select Requirements
                  </Form.Label>
                  <Col sm="3">
                    <Select
                      className={project && project.requirements && project.requirements.length ? "custom-select-input" : "readonly-select"}
                      classNamePrefix="name-select"
                      onChange={handleRequirementChange}
                      getOptionLabel={(option) =>
                        `${option.description} ${
                          option.requestSent ? "(Request Sent)" : ""
                        }`
                      }
                      getOptionValue={(option) => `${option}`}
                      value={
                        insurance?.requirementId !=null && insurance?.requirementId >=0
                          ? project?.requirementList.find(
                              (x) => x.id === insurance.requirementId && insurance.tabId === x.tabId
                            )
                          : null
                      }
                      isOptionSelected={(option) => {
                        return insurance?.requirementId === option.id && insurance.tabId === option.tabId
                          ? true
                          : false;
                      }}
                      isOptionDisabled={(option) => {
                        return option.requestSent;
                      }}
                      options={project?.requirements}
                      isSearchable={true}
                      filterOption={onRequirementFilter}
                      noOptionsMessage={() => null}
                      placeholder={"Select"}
                      styles={customStyles}
                      maxMenuHeight={100}
                      isDisabled={!project || !project.requirementList || !project.requirementList.length}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  controlId="companyEmail"
                  className="pl-2 pr-2 no-gutters"
                >
                  <Form.Label column sm="2">
                    COI Contact Email
                  </Form.Label>
                  <Col sm="3">
                    <Form.Control
                      as="input"
                      value={insurance?.email}
                      onChange={handleEmailChange}
                      className="form-control custom-input"
                      custom
                    ></Form.Control>
                  </Col>
                </Form.Group>

                <div className="clear button-group no-gutters">
                  <div className="col-sm-4 mt-5">
                    <button
                      disabled={
                        insurance.requirementId === null ||
                        !(insurance.requirementId != null && insurance.requirementId >= 0) ||
                        !insurance.email ||
                        submitting ||
                        validating
                      }
                      className="btn btn-primary"
                      onClick={handleSendClick}
                    >
                      {submitting ? "Submitting" : "Send Request"}
                    </button>
                    <button
                      className="btn btn-primary ml-2"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      {isModelOpen && (
        <Popup
          isModelOpen={isModelOpen}
          onCancel={onCancel}
          handleClick={handleClick}
          type="vendor"
        />
      )}
    </>
  );
}

export default Vendor;
