import React from "react";
import { Modal } from "react-bootstrap";

function Popup({ onCancel, isModelOpen, handleClick, type }: any) {
  return (
    <Modal
      show={isModelOpen}
      backdrop="static"
      keyboard={false}
      animation={false}
      onHide={onCancel}
    >
      <Modal.Header closeButton>
        <Modal.Title>Send COI Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>
        Your request will be visible within the next 24 hours. Thank you for your patience as we process your request.
        </h3>

        <p>
        Once processed, we will send the {type} a request for a COI and check the uploaded COI against the requirements. Meanwhile, you can follow this record’s status via Jones widgets.
        </p>
        <hr/>
        <div className="text-right mb-2">
          <button className="btn btn-primary" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn btn-primary ml-2" onClick={handleClick}>
            Finish
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Popup;
