import { toast, Slide } from "react-toastify";

export function showToastMessage(message: string) {
  toast.dismiss();
  toast.error(message, {
    position: "top-center",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    autoClose: 10000,
    style: {
      width: message.length * 8,
    },
    transition: Slide,
  });
}

export function validateEmail(mail: string) {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (regex.test(mail)) {
    return true;
  }
  showToastMessage(`"${mail}" is an invalid email address`);
  return false;
}
