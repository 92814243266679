import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Vendor from "./pages/Vendor";
import Tenant from "./pages/Tenant";

function App() {
  return (
    <Router basename="/">
      <Switch>
        <Route path="/tenant" component={Tenant}/>
        <Route path="/vendor" component={Vendor} />
      </Switch>
    </Router>
  );
}

export default App;
